import React, { Fragment, useState } from 'react'
import { Button, Card, CardBody, Col, Container, FormGroup, FormText, Row, Table, CardHeader, Collapse, Label, Input, ButtonGroup } from 'reactstrap';
import { useCaptureWorkSheetSanitaryMultiple } from '../../../hooks/worksheetsanitary/useCaptureWorkSheetSanitaryMultiple';
import Datetime from 'react-datetime';
import 'moment/locale/es';
import "react-datetime/css/react-datetime.css";
import { ToolTips } from '../../../components/tooltips/ToolTips';
import { MultiSelect } from 'primereact/multiselect';
import '../../../hooks/componentMultiSelect/MultiSelectDemo.css';
import { SearchAsync } from '../../../components/elements/SearchAsync';
import { useHistory, useParams } from 'react-router-dom';
import { CollapseDeterminations } from './CollapseDeterminations';


export const IndexCaptureWorkSheetSanitary = () => {

    let versionLayout = localStorage.getItem('layout_version');
    const history = useHistory();

    let b = 0;

    const {
        toggle, modal, modalTitle,
        list_determinations,
        id_machine_sanitarytemp,
        fechasample, 
        namesample,
        codesample, 
        nametypesample,
        id_lote_sanitarytemp,
        handleSelectLote,
        searchLoteSanitary,
        AddNewLote,
        list_determinations_save,
        AddNewMachine,
        handleSelectMachineSanitary,
        changeInputCarac,
        changeInputElement,
        handleMachineValueChange,
        handleLoteValueChange,
        saveWSS,
        formValues,
        ImprimirPdf,
        listProcessConfig_save,
        handleDeleteMachine,
        handleDeleteLote,
        idwsconfig,
        changeInputElementCheck

    } = useCaptureWorkSheetSanitaryMultiple();
    return (
        <Fragment>
            <Container fluid={true}>
                <Col sm="12">
                    <Row>
                        <Col sm="12" md="12" lg="12" xl="12" className="">
                            <Card className="shadow sizeModalDP">
                                <CardHeader className='bg-primary p-2'>
                                    <h6 className='m-t-5 m-b-0 m-l-5'>
                                        <Label className='f-16'>Información de la Muestra</Label>
                                    </h6>
                                </CardHeader>
                                <CardBody className='p-3'>
                                    <Row>
                                        <Col xs="2" sm="2" md="2" lg="2" xl="2">
                                            <Label className="f-16">Fecha: <b>{fechasample}</b></Label> <br />

                                        </Col>
                                        <Col xs="2" sm="2" md="2" lg="2" xl="2">
                                            <Label className="f-16">Tipo de Muestra: <b>{nametypesample}</b></Label> <br />

                                        </Col>
                                        <Col xs="2" sm="2" md="2" lg="2" xl="2">
                                            <Label className="f-16">Muestra: <b>{namesample}</b></Label> <br />

                                        </Col>
                                        <Col xs="3" sm="3" md="3" lg="3" xl="3">
                                            <Label className="f-16">Código: <b>{codesample}</b></Label> <br />
                                        </Col>
                                        <Col xs="3" sm="3" md="3" lg="3" xl="3">
                                            <Button block={true} size="sm"  outline color='info' onClick={saveWSS} >
                                                Guardar Datos
                                            </Button>
                                            <Button block={true} size="sm"  outline color='success' onClick={ImprimirPdf}>
                                                Imprimir
                                            </Button>
                                        </Col>
                                    </Row>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
						{formValues.listProcessConfig_save != null ?
                        formValues.listProcessConfig_save.length >= 1 && formValues.listProcessConfig_save.map((item, index) => {
						    return <CollapseDeterminations
								key={index}
								item={item}
								{...{
                                    
                                    id_machine_sanitarytemp,
                                    id_lote_sanitarytemp,handleSelectLote,searchLoteSanitary,
                                    AddNewLote,id_index:index,list_determinations_save,
                                    listProcessConfig_save,
                                    AddNewMachine,handleSelectMachineSanitary,
                                    changeInputCarac,
                                    changeInputElement,handleMachineValueChange,
                                    handleLoteValueChange,
                                    formValues,
                                    handleDeleteMachine,
                                    handleDeleteLote,
                                    changeInputElementCheck

									}}
								/>

							})
							: ""
                            }

					</Row>
                    


                </Col>
            </Container >
        </Fragment >
    )

}
