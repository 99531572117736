import { useEffect, useState, useMemo,useRef } from 'react';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { useParams } from 'react-router-dom';
import {getInformationQuoterDeterminations} from '../../services/microsanitaria/catalogosMic'
import {saveSampling,getTypeSamples,getSites,getAreas,getSamples,getThermometers,saveQuoter,saveWorkOrderMicro} from '../../services/microsanitaria/micSampling'
import {OperationsAmounts} from '../../utils/validations/calculateWithTax';
import {getAllPayMethod} from '../../services/payMethod/payMethod'
import { useHistory } from 'react-router-dom';


export const useMicSampling = () => {

    // ? CONFIGURATION
    const { code_Quatation } = useParams();
    const history = useHistory();
    const [createSweet, sweetConfirmation, , , , , sweetConfirmRepeatExam, sweetConfirmationChangeClient] = useSweetAlert();

    let id_branch = localStorage.getItem('branchId');
    let id_commercial_line = localStorage.getItem("commercialLineId");
    let url_helen = localStorage.getItem('url_helen');



    //*INFORMATION GENERAL QUATATION
    const [loadingQuoter, setLoadingQuoter] = useState(false);
    const [loadingMic, setLoadingMic] = useState(false);
    
    //*TOGGLE NOT COLLECTED SANPLE
    const [modalCollected, setModalCollected] = useState(false);
    const [dataCollected, setDataCollected] = useState([]);
    
    // *SAMPLE FORM
    const [listSite, setListSite] = useState([]);
    const [listArea, setListArea] = useState([]);
    const [listSample, setListSample] = useState([]);
    const [listThermometer, setListThermometer] = useState([]);
    const [loadingSamplings, setLoadingSamplings] = useState(false);
    const [listNot, setListNot] = useState([])

    //*MODAL SAVE SAMPLINGS
    const [modalSamplings, setModalSamplings] = useState(false);

    //* FIRMS
    const canvasRef1 = useRef(null);
    const canvasRef2 = useRef(null);
    const canvasRef3 = useRef(null);

    //* TOGGLE SAVE SAMPLINGS
    const [modalSave, setModalSave] = useState(false);
    const [modalValid, setModalValid] = useState(false);

    const [listPay, setListPay] = useState([]);
    const [total_tax, setTotal_tax] = useState(0)

    const [optionsTotal, setOptionsTotal] = useState({
        subtotal: 0,
        tax: 0,
        total: 0
      });

    // ? CALCULATE PRICES
    const [optionsPayment, setOptionsPayment] = useState({
        listPayMethods: [{
            id_pay_method: 0,
            amount: 0,
            id_branch: id_branch
        }]
    });

    //*FOMR MIC
     const [formMicSampling, setFormMicSampling] = useState({
        samples: [],
        code: null,
        id_quoter: null,
        no_samples: null,
        firm_client: null,
        firm_sampler: null,
        firm_superv: null,
        observations:null,
        sample_Form: [],
        is_form: false,
        is_view: 1,
        sample_Detail: [],
        is_detail: false,
        is_determination:false,
        quoter: {},
        firm_client_png: null,
        firm_sampler_png: null,
        firm_superv_png: null,
        sampling_plan:null,
        is_company: null,
        is_invoice:false,
        validate_cancell : false
    })
    
    //!DATA 
    useEffect(() => {
        getSearchFilter()
    }, [])

    const formatAmount = (amount, decimals) => 
    {
            return parseFloat(
                (Math.trunc(amount * 100000000) / 100000000).toFixed(decimals),
            );
    }

     //*CALCULATE AMOUNT
     useMemo(() => {
      
        if (formMicSampling.samples?.length > 0)
        { 
            let subtotal = 0;
            let taxes = 0;
            let total = 0;
            let _valid = ""

            formMicSampling.samples.forEach(_find => {
            
                if (!_find.not_collected && _find.id_mic_sampling !== null) {

                    setTotal_tax(_find?.value_iva)

                    switch (_find.config_iva) {
                        case true:
                            if (_find.discount !== 0) {
                                
                                if (formMicSampling.is_company !== null) {
                                    _valid = OperationsAmounts(1,_find?.price,true,_find?.value_iva,0)       
                                }
                                else _valid = OperationsAmounts(1,_find?.discount,true,_find?.value_iva,0)    
                            }
                            else _valid = OperationsAmounts(1,_find?.price,true,_find?.value_iva,0)   
                                
                            // ? TOTAL
                            _find.discount_tota = _valid.total
                            _find.can_total = _valid.total
                            subtotal += _valid.subtotal; 
                            taxes += _valid.total_tax 
                            total += _valid.total
                      
                        break;
                        default:
                            if (_find?.discount !== 0) {
                                _valid = OperationsAmounts(1,_find?.price,false,_find?.value_iva,_find?.discount)
                                }
                                else _valid = OperationsAmounts(1,_find?.price,false,_find?.value_iva,_find?.discount)
                                      
                                // ? TOTAL
                                _find.discount_tota = _valid.total
                                _find.can_total = _valid.total
                                subtotal += _valid.subtotal; 
                                taxes += _valid.total_tax 
                                total += _valid.total
                            break;
                    }                     
                }
            })   
            setOptionsTotal({
                subtotal: formatAmount(subtotal,  2),
                tax: formatAmount(taxes,  2),
                total: Math.round(formatAmount(total, 2))
            })
        }
    }, [formMicSampling])


    const handleView = async (_view) => 
    {
        if (_view !== null && _view !== "") {
            if (_view === 1 ) {
                setFormMicSampling({
                    ...formMicSampling,
                    is_view: _view,
                    is_detail: true
                })
            }
            else {
                if (_view === 2) {
                    if (listNot.length > 0) {
                        handleToggleValid();
                        setFormMicSampling({
                            ...formMicSampling,
                            is_view: 1,
                            is_detail: true
                        })
                        return;
                    }  
                }
                setFormMicSampling({
                    ...formMicSampling,
                    is_view: _view,
                    is_detail: false
                })
            }
        }
    }

    const getPayMethod = async (_type) =>
        {
            const pay = await getAllPayMethod(3);
    
            if (pay?.length > 0)
            {
                const list = pay?.map(_find => {
                    return {
                        id_pay_method: _find?.id_pay_method,
                        name: _find?.abbreviation + "-" + _find?.name,
                    }
                });
                setListPay(list);
            }
        }
 
    const getSearchFilter = async (_valid,_valid2) =>
        {
            
            if (code_Quatation !== "" && code_Quatation !== undefined && code_Quatation !== null) {

                if (_valid === undefined) {
                    setLoadingQuoter(true);
                }else setLoadingSamplings(true)
                
                const quoter = await getInformationQuoterDeterminations(code_Quatation);

                if (quoter !== null) {
                    setLoadingQuoter(false);

                    if (_valid) {
                        createSweet("success", "success", "Se guardo correctamente");
                        setLoadingSamplings(false);
                        handleToggleSamplings(); // ? CONFIGUARATION   
                    }
                 
                    if (quoter?.samples?.length > 0) {
                        // ? FIND SAMPLING

                        const sample = await getTypeSamples();
                        const _listSamplings =  sample.map(_find => {
                            return {
                                id_mic_type_sample: _find?.id_mic_type_sample,
                                name: _find?.name
                            }
                        })

                        let _prueba = {}

                        const _alid = quoter?.samples?.map(sample => {
                            if (sample.id_mic_detail_sample === null) {                                
                                return {
                                    folio: sample.folio
                                };
                            }
                            return null;
                        }).filter(Boolean); 
                        
                        if (_alid.length > 0) {
                            setListNot(_alid);
                        } else {
                            setListNot([]);    
                        }

                        const _valid = quoter?.samples?.map(_valid => { 
                        let _name = _listSamplings.find(x => x.id_mic_type_sample === _valid?.id_mic_type_sample);

                            const _determinations = _valid?.listDeterminations.map(_info => {
                                return{
                                    name: _info?.name ?? "---",
                                    new_determination: _info?.new_determination ?? "---"
                                }
                            })

                            const _newView = quoter.samples.reduce((latest, current) => {
                                return (!latest || (new Date(current?.date_collected) > new Date(latest?.date_collected))) ? current : latest;
                            }, null);

                            if (_newView) {
                                const _name = _listSamplings.find(x => x.id_mic_type_sample === _newView?.id_mic_type_sample);
                        
                                const _determinations = _newView?.listDeterminations.map(_info => ({
                                    name: _info?.name ?? "---",
                                    new_determination: _info?.new_determination ?? "---"
                                }));
                        
                                _prueba = {
                                    id_quoter_type_sample: _newView?.id_quoter_type_sample ?? null,
                                    id_mic_type_sample: _newView?.id_mic_type_sample ?? null,
                                    folio: _newView?.folio ?? "----",  
                                    id_quoter: quoter?.cotizacion?.id_quoter ?? null,
                                    id_mic_detail_sample: _newView?.id_mic_detail_sample ?? null,
                                    id_mic_sampling: _newView?.id_mic_sampling ?? null,
                                    id_mic_site: _newView.id_mic_site ?? null,
                                    id_mic_site_areas: _newView.id_mic_site_areas ?? null,
                                    id_mic_sample: _newView?.id_mic_sample ?? null,
                                    id_patient: _newView?.id_patient ?? null,
                                    id_thermometer: _newView?.id_thermometer ?? null,
                                    temperature: _newView?.temperature ?? null,
                                    quantity: _newView?.quantity ?? null,
                                    factor_correction: _newView?.factor_correction ?? null,
                                    observations: _newView?.observations ?? null,
                                    not_collected: _newView?.not_collected ?? null,
                                    date_collected: _newView?.date_collected ?? null,
                                    required_temperature: _newView?.required_temperature,
                                    list_Determinations: _determinations,
                                    name_Sampling: _name,
                                    name_mic_sample: _newView?.name_mic_sample ?? null,
                                    name_mic_site: _newView?.name_mic_site ?? null,
                                    name_mic_site_areas: _newView?.name_mic_site_areas ?? null,
                                    name_thermometer: _newView?.name_thermometer ?? null,  
                                };
                            }
                            return{
                                id_quoter_type_sample: _valid?.id_quoter_type_sample ?? null,
                                id_mic_type_sample : _valid?.id_mic_type_sample ?? null,
                                folio: _valid?.folio ?? "----",  
                                id_quoter: quoter?.cotizacion?.id_quoter ?? null,
                                id_mic_detail_sample: _valid?.id_mic_detail_sample ?? null,
                                id_mic_sampling: _valid?.id_mic_sampling ?? null,
                                id_mic_site: _valid.id_mic_site ?? null,
                                id_mic_site_areas: _valid.id_mic_site_areas ?? null,
                                id_mic_sample: _valid?.id_mic_sample ?? null,
                                id_patient: _valid?.id_patient ?? null,
                                id_thermometer: _valid?.id_thermometer ?? null,
                                temperature: _valid?.temperature ?? null,
                                quantity: _valid?.quantity ?? null,
                                factor_correction: _valid?.factor_correction ?? null,
                                observations: _valid?.observations ?? null,
                                not_collected: _valid?.not_collected ?? null,
                                date_collected: _valid?.date_collected ?? null, // ? estatus
                                required_temperature: _valid?.required_temperature,
                                list_Determinations:_determinations,
                                name_Sampling:_name,
                                name_mic_sample: _valid?.name_mic_sample ?? null,
                                name_mic_site: _valid?.name_mic_site ?? null,
                                name_mic_site_areas: _valid?.name_mic_site_areas ?? null,
                                name_thermometer: _valid?.name_thermometer ?? null,
                                config_iva: _valid?.config_iva ?? false,
                                discount: _valid?.discount ?? 0,
                                price: _valid?.price ?? 0,
                                value_iva: _valid?.value_iva ?? 16
                            }
                        })
                        
                        setFormMicSampling({
                            ...formMicSampling,
                            samples: _valid ?? [],
                            code: quoter?.cotizacion?.code ?? null,
                            id_quoter: quoter?.cotizacion?.id_quoter ?? null,
                            no_samples: quoter?.cotizacion?.no_samples  ?? null,
                            observations:quoter?.observations ?? null,
                            sample_Form: [],
                            is_form: false,
                            quoter: quoter?.cotizacion,
                            firm_client_png: quoter?.firm_client ?? null,
                            firm_sampler_png: quoter?.firm_sampler ?? null,
                            firm_superv_png: quoter?.firm_superv ?? null,
                            sampling_plan:quoter?.sampling_plan ?? null,
                            sample_Detail: _prueba ?? [],
                            is_detail: true,
                            is_company: quoter?.cotizacion?.id_company === 0 ?  null : quoter?.cotizacion?.id_company ?? null,
                        });   
                    }
                }  
            }
    }

    //*TOGGLE EXAMS
    const handleToggleCollected = () => {
        setModalCollected(!modalCollected)
    }

    const handleNotCollected = (_id_quoter_type_sample) => {
        
        if (_id_quoter_type_sample !== null &&  _id_quoter_type_sample !== undefined && _id_quoter_type_sample !== "" ) {
            let _valid = formMicSampling?.samples.find(x => x.id_quoter_type_sample === _id_quoter_type_sample);
       
            setDataCollected({
               folio: _valid?.folio ?? "----",
               id_quoter_type_sample: _valid?.id_quoter_type_sample ?? null,
               id_mic_type_sample: _valid?.id_mic_type_sample ?? null
            })
            handleToggleCollected();            
        }
        else createSweet("wanirnig", "warning", "Muestra", "No se puede seleccionar este muestra.");
    };

    const saveDetailSamplingsNotCollected = async (_folio,_id_quoter_type_sample,_id_mic_type_sample) => {
        
        if (_folio !== null && _id_quoter_type_sample !== null ) {

            const response = await saveSampling({
                date_collected: "",
                factor_correction: 0,
                folio: _folio,
                id_mic_detail_sample: null,
                id_mic_sample: null,
                id_mic_sampling: null,
                id_mic_site: null,
                id_mic_site_areas: null,
                id_mic_type_sample: _id_mic_type_sample,
                id_patient: null,
                id_quoter:formMicSampling.id_quoter,
                id_quoter_type_sample: _id_quoter_type_sample,
                id_thermometer: null,
                not_collected: true,
                observations: "",
                quantity: "",
                required_temperature: false,
                temperature: ""
            });
            
            if (response.code === 200) {
                getSearchFilter()
                handleToggleCollected();
    
            } else  createSweet("wanirnig", "warning", response?.data?.message);

        } else  createSweet("wanirnig", "warning", "Muestra", "No se pudo actualizar correctamente.");
    }

    // * SAMPLE FORM 
    const handleSearchSampling = (_id_quoter_type_sample) => 
    {
        if (_id_quoter_type_sample !== null && _id_quoter_type_sample !== "" && _id_quoter_type_sample !== undefined) {

            let _search = formMicSampling?.samples.find(x => x.id_quoter_type_sample === _id_quoter_type_sample);

            if (_search !== null) {
                setFormMicSampling({
                    ...formMicSampling,
                    sample_Form: _search,
                    is_form: true
                });
                // ? CONFIGURATION CATALOGS
                getTypeSites();
                getSamplesList();
            }
            else {
                setFormMicSampling({
                    ...formMicSampling,
                    sample_Form: _search,
                    is_form: false
                });
            }
        }
        else createSweet("warning", "warning","Muestreo", "No se encontro ningún resultado");
    }

    const handleResetSamplings = () => 
    {
        let _validForm = formMicSampling?.sample_Form

        if (_validForm?.id_mic_sample !== null ||  _validForm?.id_mic_site !== null || _validForm?.id_mic_site_areas || _validForm?.observations || _validForm?.quantity ) {
            handleToggleSamplings()
            setFormMicSampling({
                ...formMicSampling,
                validate_cancell: true
            })
        }
        else {
        setFormMicSampling({
            ...formMicSampling,
            sample_Form: [],
            is_form: false
        });
        }
    }

    // ? SAVE CANCELL

    const cancell_Samplings = () => 
    {
        setFormMicSampling({
            ...formMicSampling,
            sample_Form: [],
            is_form: false,
            validate_cancell: false
        });
        handleToggleSamplings()
    }

    // ? CONFIGURATION CATALOGS
     const getTypeSites = async () =>
        {
            const site = await getSites();
    
            if (site?.length > 0)
            {
                const list = site?.map(_find => {
                    return {
                        value: _find?.id_mic_site,
                        label: _find?.name,
                    }
                });
                setListSite(list);
            }
            else createSweet("warning", "warning","Muestra", "Sin resultados");
    }

    const handleChangeSites = async (e) => 
    {
        if (e !== null && e?.value !== undefined && e?.value !== "") {
            const selectedIdMicSite = e?.value;
        
            setFormMicSampling(_search => ({
                ..._search,
                sample_Form: {
                    ..._search?.sample_Form,
                    id_mic_site: {
                    value: e?.value,
                    label: e?.label,
                    },
                    id_mic_site_areas: null
                }
            }));
        
            const area = await getAreas(selectedIdMicSite);
        
            if (area !== null) {
                const list = area?.map(_find => {
                    return {
                        value: _find?.id_mic_site_area,
                        label: _find?.name,
                    }
                });
                setListArea(list);
            }
             else  createSweet("warning", "warning","Sitio", "Sin resultados");
        }
        else  createSweet("warning", "warning","Sitio", "Sin resultados"); 
    };

    const handleChangeArea = async (e) => 
    {
            if (e !== null && e?.value !== undefined && e?.value !== "") {
        
                setFormMicSampling(_search => ({
                    ..._search,
                    sample_Form: {
                        ..._search?.sample_Form,
                        id_mic_site_areas: {
                            value: e.value,
                            label: e?.label,
                        }
                    }
                }));
            }
            else  createSweet("warning", "warning","Area", "Sin resultados");
    };

    const getSamplesList = async () =>
            {
                const sample = await getSamples();
        
                if (sample?.length > 0)
                {
                    const list = sample?.map(_find => {
                        return {
                            value: _find?.id_mic_sample,
                            label: _find?.name,
                        }
                    });
                    setListSample(list);
                }
                else createSweet("warning", "warning","Muestra", "Sin resultados");
    }

    const handleChangeSample = async (e) => 
    {
        if (e !== null && e?.value !== undefined && e?.value !== "") {
        
            setFormMicSampling(_search => ({
                ..._search,
                sample_Form: {
                    ..._search?.sample_Form,
                    id_mic_sample: {
                        value: e?.value,
                        label: e?.label,
                    }
                }
            }));
        } else  createSweet("warning", "warning","Area", "Sin resultados");
    };

    const handleChangeQuantity = async (e) => 
    {
        const _quantity = e?.target?.value;  

        if (e !== null && e?.target?.value !== undefined && e?.target?.value !== "") {
            setFormMicSampling(_search => ({
                ..._search,
                sample_Form: {
                    ..._search?.sample_Form,
                    quantity: _quantity
                }
            }));
        }
        else {
            setFormMicSampling(_search => ({
                ..._search,
                sample_Form: {
                    ..._search?.sample_Form,
                    quantity: null,
                }
            }));
        }
    }

    const handleChangeObservations = async (e) => 
    {
        const _observations = e?.target?.value;  
    
        if (e !== null && e?.target?.value !== undefined && e?.target?.value !== "") {
            setFormMicSampling(_search => ({
                ..._search,
                sample_Form: {
                    ..._search?.sample_Form,
                     observations: _observations
                }
            }));
        }
        else {
            setFormMicSampling(_search => ({
                ..._search,
                sample_Form: {
                    ..._search?.sample_Form,
                    observations: null,
                }
            }));
        }
    }

    // ! TEMPERATURE
    const handleRequiredTemperature = async (e) => 
    {
        const _temperature = e?.target?.checked; 

        if (e !== null && e?.target?.checked !== undefined && e?.target?.checked !== "") {
            setFormMicSampling(_search => ({
                ..._search,
                sample_Form: {
                    ..._search?.sample_Form,
                    required_temperature: _temperature
                }
                }));

                if (_temperature) {
                    getTypeThermometers()    
                }
        }
        else  createSweet("warning", "warning","Temperatura", "No se puede agragar temperatura");
    }

    const getTypeThermometers = async () =>
        {
            const thermometers = await getThermometers();
    
            if (thermometers?.length > 0)
            {
                const list = thermometers?.map(_find => {
                    return {
                        value: _find?.id_thermometer,
                        label: _find?.name_thermometer,
                        factor: _find?.correction_factor
                    }
                });
                setListThermometer(list);
            }
            else createSweet("warning", "warning","Muestra", "Sin resultados");
    }

    const handleChangeThermometers = async (e) => 
        {
            if (e !== null && e?.value !== undefined && e?.value !== "") {
                setFormMicSampling(_search => ({
                    ..._search,
                    sample_Form: {
                        ..._search?.sample_Form,
                        id_thermometer: {
                            value: e?.value,
                            label: e?.label,
                        },
                        factor_correction: e?.factor,
                        temperature: ""
                    }
                }));
            }
            else {                
                setFormMicSampling(_search => ({
                    ..._search,
                    sample_Form: {
                        ..._search?.sample_Form,
                        id_thermometer: null,
                        factor_correction: ""
                    }
                }));
            }
    };

    const handleChangeTemperature = async (e) => 
        {
            if (e !== null && e?.target?.value !== undefined && e?.target?.value !== "") {

            let _temperature = parseFloat(e?.target?.value) ; 
            let _factor_correction = parseFloat(formMicSampling?.sample_Form?.factor_correction);

            // ? FACTOR CORRECTION
            let _sumCorrection = _temperature + _factor_correction
                
                setFormMicSampling(_search => ({
                    ..._search,
                    sample_Form: {
                        ..._search?.sample_Form,
                        temperature: _temperature,
                        factor_correction2: _sumCorrection 
                    }
                }));
            }
           else {
            setFormMicSampling(_search => ({
                ..._search,
                sample_Form: {
                    ..._search?.sample_Form,
                    temperature: null,
                }
            }));
           }
    };
    // ! END TEMPERATURE
    // ? -----------

    //*TOGGLE EXAMS
    const handleToggleSamplings = () => {
        setModalSamplings(!modalSamplings)
    }

    const handlePreviewSamplings = () => {
        handleToggleSamplings()
    };

    const saveSamplings = async () => {
        
            const response = await saveSampling({
                date_collected: "",
                factor_correction: formMicSampling?.sample_Form?.factor_correction === null ? 0 : formMicSampling?.sample_Form?.factor_correction ,
                folio: formMicSampling?.sample_Form?.folio,
                id_mic_detail_sample: formMicSampling?.sample_Form?.id_mic_detail_sample,
                id_mic_sample: formMicSampling?.sample_Form?.id_mic_sample.value,
                id_mic_sampling: formMicSampling?.sample_Form?.id_mic_sampling,
                id_mic_site: formMicSampling?.sample_Form?.id_mic_site.value,
                id_mic_site_areas: url_helen !== "https://almass.helen-sw.com" ? (formMicSampling?.sample_Form?.id_mic_site_areas.value) : null,
                id_mic_type_sample: formMicSampling?.sample_Form?.id_mic_type_sample,
                id_patient: null,
                id_quoter:formMicSampling?.sample_Form?.id_quoter,
                id_quoter_type_sample: formMicSampling?.sample_Form?.id_quoter_type_sample,
                id_thermometer: formMicSampling?.sample_Form?.required_temperature === true ?  formMicSampling?.sample_Form?.id_thermometer.value : null,
                not_collected: false,
                observations: formMicSampling?.sample_Form?.observations === null ? "" : formMicSampling?.sample_Form?.observations ,
                quantity: formMicSampling?.sample_Form?.quantity === null ? "" : formMicSampling?.sample_Form?.quantity,
                required_temperature: formMicSampling?.sample_Form?.required_temperature === null ? false : formMicSampling?.sample_Form?.required_temperature,
                temperature: formMicSampling?.sample_Form?.temperature === null ? "" : formMicSampling?.sample_Form?.temperature,
            });
            
            if (response.code === 200) {
                getSearchFilter(true) 
            } else  createSweet("wanirnig", "warning", response?.data?.message);
    }

     // * VIEW SAMPLINGS
    const handleViewSamplings = (_id_quoter_type_sample) => 
    {  
        if (_id_quoter_type_sample !== null && _id_quoter_type_sample !== "" && _id_quoter_type_sample !== undefined) {

            let _search = formMicSampling?.samples.find(x => x.id_quoter_type_sample === _id_quoter_type_sample);

            if (_search !== null) {
                setFormMicSampling({
                    ...formMicSampling,
                    sample_Detail: _search,
                    is_detail: true
                });
            }
            else {
                setFormMicSampling({
                    ...formMicSampling,
                    sample_Detail: _search,
                    is_detail: false
                });
            }
        }
        else createSweet("warning", "warning","Muestreo", "No se encontro ningún resultado");
    }

    const handleDetermination = (_valid) => 
    {
        setFormMicSampling({
            ...formMicSampling,
            is_determination: _valid
        })
    }

    // ? SAMPLING PLAN
    const handleChangeFirm = (myRef, type) => {
        const Draw = myRef.current;
        if (type === "responsable") {
            setFormMicSampling(_search => ({
                ..._search,
                firm_sampler: (Draw.getDataURL('png'))
            }));

        } else if (type === "cliente") {
            setFormMicSampling(_search => ({
                ..._search,
                firm_client: (Draw.getDataURL('png'))
            }));
        } else if (type === "supervisor") {
            setFormMicSampling(_search => ({
                ..._search,
                firm_superv: (Draw.getDataURL('png'))
            }));
        }
    }

    const repeatFirm = (myRef, type) => {
        
        const Draw = myRef.current;
        if (Draw && Draw.eraseAll) {
            Draw.eraseAll();
        } 
    
        if (type === "sampler") {
            setFormMicSampling(prevState => ({
                ...prevState,
                firm_sampler: null
            }));
        } else if (type === "client") {
            setFormMicSampling(prevState => ({
                ...prevState,
                firm_client: null
            }));
        } else if (type === "superv") {
            setFormMicSampling(prevState => ({
                ...prevState,
                firm_superv: null
            }));
        }
    }; 

    const handleChangeObservationsPlan = async (e) => 
    {
            const _observations = e?.target?.value;  
        
            if (e !== null && e?.target?.value !== undefined && e?.target?.value !== "") {
                setFormMicSampling(_search => ({
                    ..._search,
                    observations: _observations
                }));
            }
            else {
                setFormMicSampling(_search => ({
                    ..._search,
                    observations: null
                }));
            }
    }

    const handleChangeNumPlan = async (e) => 
        {
            const _observations = e?.target?.value;  
        
            if (e !== null && e?.target?.value !== undefined && e?.target?.value !== "") {
                setFormMicSampling(_search => ({
                    ..._search,
                    sampling_plan: _observations
                }));
            }
            else {
                setFormMicSampling(_search => ({
                    ..._search,
                    sampling_plan: null
                }));
            }
    }
   
    const saveSamplingPlan = async () => {

            const response = await saveQuoter({
                cotizacion: formMicSampling.quoter,
                observations: formMicSampling.observations,
                firm_sampler: formMicSampling.firm_sampler,
                firm_client: formMicSampling.firm_client,
                firm_superv: formMicSampling.firm_superv,
                sampling_plan: formMicSampling.sampling_plan,
            });
            
            if (response.code === 200) {
                createSweet("success", "success", "Plan de Muestreo", "Se han guardado correctamente los datos");

                setFormMicSampling({
                    ...formMicSampling,
                   quoter: response?.data?.cotizacion ?? {},
                   firm_sampler_png: response?.data?.firm_sampler ?? null ,
                   firm_client_png: response?.data?.firm_client ?? null,
                   firm_superv_png: response?.data?.firm_superv ?? null,
                   observations:response?.data?.observations ?? null,
                   sampling_plan: response?.data?.sampling_plan ?? null,
                   is_view: 3
                });               
            } else  createSweet("wanirnig", "warning", response?.data?.message);
    }
    // ? ---------------

    // * SAVE SAMPLISG
    
    //*TOGGLE SAVE
    const handleToggleSave = () => 
    {
        setModalSave(!modalSave)
    }
      //*TOGGLE SAVE
    const handleToggleValid = () => 
    {
        setModalValid(!modalValid)
    }

    const handleViewSampligs = () => 
    {
        handleToggleSave()
        getPayMethod() 
    }
    // ! PAYMENT METHOD

    const handleAddPayMethod = () => {
        setOptionsPayment(prev => ({
            ...prev,
            listPayMethods: [
                ...prev?.listPayMethods,
                {
                    id_pay_method: 0,
                    amount: 0.0,
                    is_edited: false,
                    deleted: false,
                    id_branch: 0
                }
            ]
        }));
    };

    const handleDeletePayMethod = (_key) => {
    
        let _array = [...optionsPayment.listPayMethods];
        let _amount = 0;
    
        if (_array.length > 1) {    
            _array.splice(_key, 1);
        }
    
        _array.forEach(_find => {
            _amount += parseFloat(_find.amount);
        });
    
        setOptionsPayment({
            ...optionsPayment,
            listPayMethods: _array
        });
    
    };

    // ? CONFIGURATION SELECT
    const handleGiveChange = (e, name, array) => {
        const newValue = e.target.value;
        const updatedList = [...optionsPayment.listPayMethods];
        updatedList[e.target.dataset.id][name] = newValue;
    
        setOptionsPayment(prevState => ({
            ...prevState,
            listPayMethods: updatedList
        }));

    };

    // ? CONFIGURATION SELECT
    const handleGiveMethod = (e, name, array) => {
        const newValue = e.target.value;
        const updatedList = [...optionsPayment.listPayMethods];
        updatedList[e.target.dataset.id][name] = newValue;
    
        setOptionsPayment(prevState => ({
            ...prevState,
            listPayMethods: updatedList
        }));
    };

    // ! INVOICES
    const handleRequiredInvoice = async (e) => 
    {
        const _invoices = e?.target?.checked; 

        if (e !== null && e?.target?.checked !== undefined && e?.target?.checked !== "") {
            setFormMicSampling(_search => ({
                ..._search,
                is_invoice: _invoices
            }));
    
        }
        else  createSweet("warning", "warning","Temperatura", "No se puede marcar la factura");
    }

    const saveMicroWorkorder = async (_type) => {

        if (_type === 2) {
            if (listNot.length > 0) {
                handleToggleValid()
                return;
            }     
        }

        if (_type === 1) {            
            setLoadingQuoter(true)
        } else setLoadingMic(true)
        
            const response = await saveWorkOrderMicro({
                id_branch: id_branch,
                id_commercial_line: id_commercial_line,
                id_quoter: formMicSampling?.id_quoter,
                iva: optionsTotal.tax,
                listPayMethods: optionsPayment?.listPayMethods,
                required_invoice_patient: formMicSampling?.is_invoice
            });
            
            if (response.code === 200) {
                setLoadingQuoter(false)
                setLoadingMic(false)
                createSweet("success", "success", "Muestreo","Se genero correctamente");
                if (_type === 1) {
                    handleToggleSave();
                }
                history.push("../../sanitaria/agenda")
            } else  createSweet("wanirnig", "warning", response?.data?.message);
    }

      //!QUOTER 
    const handleNavigateSampling= (_code) => {
        history.push("../cotizacion");
    }

    console.log("🚀 ~ useMicSampling ~ formMicSampling:", formMicSampling)

  return {

    // * FORM SAMPLE
    loadingQuoter,formMicSampling,handleView,

    // * INPUTS
    handleNotCollected,handleToggleCollected,modalCollected,dataCollected,

    // * SAVE SAMPLINGS
    saveDetailSamplingsNotCollected,handleSearchSampling,handleResetSamplings,cancell_Samplings,

    // * SAMPLE FORM
    handleChangeSites,handleChangeArea,handleChangeSample,handleChangeQuantity,handleChangeObservations,
    handleRequiredTemperature,handleChangeThermometers,handleChangeTemperature,

    // * LIST SAMPLES
    listSite,listArea,listSample,listThermometer,

    // * SAVE SAMPLINGS
    handleToggleSamplings,modalSamplings,handlePreviewSamplings,saveSamplings,loadingSamplings,

    // * VIEWS
    handleViewSamplings,handleDetermination,listNot,

    // * SAMPLING PLAN
    handleChangeFirm,repeatFirm,canvasRef1,canvasRef2,canvasRef3,
    handleChangeObservationsPlan,handleChangeNumPlan,saveSamplingPlan,

    // * CALCULATE PRICES
    optionsTotal,total_tax,

    // * SAVE SAMPLINGS
    handleViewSampligs,handleToggleSave,modalSave,listPay,optionsPayment,handleAddPayMethod,handleDeletePayMethod,
    handleGiveChange,handleGiveMethod,saveMicroWorkorder,loadingMic,handleToggleValid,modalValid,

    //*INVOICES
    handleRequiredInvoice,

    // * QUATATION MIC
    handleNavigateSampling,

    // ? GLOBAL
    url_helen
    
  }
}