import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, ButtonGroup, Card, CardBody, Row, Col, Input, Tooltip, Table } from 'reactstrap';
import { InputAnalyte } from './InputAnalyte';
import './styleCaptureResults.css';
import { sendRequest } from '../../../hooks/requests/useRequest';
import { useSweetAlert } from '../../../hooks/sweetAlerts/useSweetAlert';
import { useHistory } from 'react-router-dom';
import { validarSesion } from '../../../hooks/tokens/useToken';
import moment from 'moment';
import { exam } from '../../../constant';


export const ViewExam = ({
    typeArray, sectionProfileId, examen, saveIndividualAnalyte, releaseExam, validateAnalyte, validateExam, analytes, idPat, namePatiente, nameP, surnameP, surnameM, curves = null,
     previewWorkOrder, removeValidationFromExamOrProfile
}) => {
    // ////console.log(days_age);
    
    const getBranchProcess = () => {
        try {
            let json = localStorage.getItem("list_branch_storage");
            var branches = JSON.parse(json);
            var idx_branch = branches.findIndex(b => b.id_branch == parseInt(id_branch));
            if(idx_branch == -1) return [];
            else return branches[idx_branch].ids_branch_process;
        } catch (error) {
            return [];
        }
    }

    let id_branch = localStorage.getItem('branchId');
    let ids_branch_process = getBranchProcess();
    let enable_interface_imagenology = localStorage.getItem('enable_interface_imagenology');
    enable_interface_imagenology = (enable_interface_imagenology == "true" ? true : false);

    const [examMethod, setExamMethod] = useState(0);
    const [appCodeOrMethodExam, setAppCodeOrMethodExam] = useState(0);
    const [readyToRelease, setReadyToRelease] = useState(false);
    const [releaseData, setReleaseData] = useState(null);
    const [readyToValidate, setReadyToValidate] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

    const [createSweet] = useSweetAlert();
    const history = useHistory();

    const [dataSample, setDataSample] = useState(null)
    const [ageSample, setAgeSample] = useState(null);
    const [ageDaysSample, setAgeDaysSample] = useState(0);
    const [idGenderSample, setIdGenderSample] = useState(0);
    const [statePaint, setStatePaint] = useState(false)
   const [riguroso, setRiguroso] = useState(false);
  //  let  riguroso = false


  //console.log("Principal",examen);



    const changeMethod = ({ target }) => {
        let method = examen.methods.find(x => x.id_exam_method == target.value);
        if (method !== null) {
            setExamMethod(-1);
            setExamMethod(method.id_method);
            setAppCodeOrMethodExam(-1);
            setAppCodeOrMethodExam(method.id_exam_method);
        }
    }

    const canReport = (id_branch_wo) => {
        if(parseInt(id_branch) == id_branch_wo) return true;
        else {
            var idx = ids_branch_process.findIndex(id => id == id_branch_wo);
            return (idx >= 0);
        }
    }

    useEffect(() => {
        //console.log("2");

        let method = examen.methods.find(x => x.id_exam_method == examen.method_selected);

        //console.log(examen);

        if (examen.method_selected !== null) {
            setExamMethod(method.id_method);
            setAppCodeOrMethodExam(examen.method_selected);
        } else {
            if (examen.methods.length > 0) {

                if (examen.id_app_code_method_default !== null && examen.id_app_code_method_default !== undefined) {

                    let examen_default = examen.methods.find(x => x.id_exam_method === examen.id_app_code_method_default);
                    if (examen_default !== undefined && examen_default !== null) {
                        setExamMethod(examen_default.id_method);
                        setAppCodeOrMethodExam(examen_default.id_exam_method);
                    } else {
                        setExamMethod(examen.methods[0].id_method);
                        setAppCodeOrMethodExam(examen.methods[0].id_exam_method);
                    }
                } else {
                    setExamMethod(examen.methods[0].id_method);
                    setAppCodeOrMethodExam(examen.methods[0].id_exam_method);
                }

            }
        }

        let validacion = true;
        let riguroso_validate=true;


        if (!enable_interface_imagenology && examen.is_imagenology) {
            if (examen.imagenology != null) {
                if (examen.imagenology.required) {
                    if (examen.imagenology.isCaptured === false) {
                        validacion = false;

                    }
                }
            }
            setReadyToRelease(validacion);
        }
        else if (!enable_interface_imagenology && !examen.is_imagenology) {
            if(examen.analytes !== null && examen.analytes.length > 0){
                if(examen.analytes.filter(a => a.required).length == 0){
                    validacion = false;
                    riguroso_validate=true;
                }

                examen.analytes.forEach(element => {
                    if (element.required) {
                        riguroso_validate=false;
                        if (element.isCaptured === false) {
                            validacion = false;
                            riguroso_validate=true;
                        }else{
                            if(validacion==false){
                                riguroso_validate=true;
                            }else{
                                riguroso_validate=false;
                            }

                        }
                    }else{
                        riguroso_validate=false; 


                    }

                });
            }


            // examen.analytes !== null && examen.analytes.forEach(element => {
            //     if (element.required) {
            //         if (element.isCaptured === false) {
            //             validacion = false;
            //             riguroso_validate=true;

            //         }
            //     }
            // });
           // setRiguroso(riguroso_validate);
           setRiguroso(riguroso_validate);
            setReadyToRelease(validacion);
        }

        if (examen.isRelease) {
            let { user } = examen;
            let obj = {
                "date": examen.release_date,
                "user": `${user.name} ${user.paternal_surname} ${user.maternal_surname} / ${user.username}`
            }
            setReleaseData(obj);
        }
        else {
            //console.log("pasassssss");
        }
        if (examen.id_mic_detail_sample > 0) {
            getDataDetailSample().then(resp => {
                setStatePaint(true);
            })
        } else {
            setStatePaint(true)
        }

    }, [])

    useEffect(() => {
        //console.log("1");
        let validacion = true;
        let validacionExam = true;
        let riguroso_validate=true;

        // debugger;
        if (!enable_interface_imagenology && examen.is_imagenology) {
            if (examen.imagenology !== null) {
                if (examen.imagenology.required) {
                    if (examen.imagenology.isCaptured === false) {
                        validacion = false;
                        validacionExam = false;
                    }
                    if (examen.imagenology.userValidate === null) {
                        validacion = false;
                    }
                }

                setReadyToRelease(validacion);
                setReadyToValidate(validacionExam);
            }
        }
        else if (!enable_interface_imagenology && !examen.is_imagenology) {
            // examen.analytes !== null && examen.analytes.forEach(element => {
            //     if (element.required) {
            //         if (element.isCaptured === false) {
            //             validacion = false;
            //             validacionExam = false;
            //         }
            //         if (element.userValidate === null) {
            //             validacion = false;
            //         }
            //     }
            // });
           // setRiguroso(riguroso_validate);
           if(examen.analytes !== null && examen.analytes.length > 0){
            if(examen.analytes.filter(a => a.required).length == 0){
                validacion = false;
                validacionExam = false;
                riguroso_validate=true;
            }

            examen.analytes.forEach(element => {
                if (element.required) {
                    riguroso_validate=false;
                    if (element.isCaptured === false) {
                        validacion = false;
                        validacionExam = false;
                        riguroso_validate=true;
                    }else{
                        riguroso_validate=false;
                        if(validacion==true){
                            riguroso_validate=false;
                        }else{
                            riguroso_validate=true;
                        }
                    }
                    if (element.userValidate === null) {
                        validacion = false;
                    }
                
                }else{

                    riguroso_validate=false;

                }
            });
        } 
        setRiguroso(riguroso_validate);

            setReadyToRelease(validacion);
            setReadyToValidate(validacionExam);
        }

        if (examen.isRelease) {
            let { user } = examen;
            let obj = {
                "date": examen.release_date,
                "user": `${user.name} ${user.paternal_surname} ${user.maternal_surname} / ${user.username}`
            }
            setReleaseData(obj);
        }
        else {
            setReleaseData(null);

            //console.log("pasas 2");
        }


    }, [examen.imagenology, examen.analytes, examen.isRelease])


    //console.log(releaseData);





    const getDataDetailSample = async () => {
        let requestOptions = {
            method: 'GET',
        };
        const respuesta = await sendRequest(requestOptions, 'QuoterDeterminations/GetDataDetailSample/' + examen.id_mic_detail_sample);

        if (respuesta.code === 200) {
            setDataSample(respuesta.data)
            if (respuesta.data.patient !== null) {
                setIdGenderSample(respuesta.data.id_gender)
                let fecha = new Date(respuesta.data.birthday);
                let edad = calculateAge(fecha);
                setAgeSample(10);
                let days_age = 0;
                if (edad <= 0) {
                    let fecha1 = moment(respuesta.data.birthday);
                    let fecha2 = moment();
                    days_age = fecha2.diff(fecha1, 'days');
                    setAgeDaysSample(days_age)
                }
            }
            return respuesta.data;
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getDataDetailSample);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Captura de resultados", respuesta.data.msg);
        }
        else {
            createSweet("error", "warning", "Captura de resultados", respuesta.data.msg);
        }
    }

    const calculateAge = (birthday) => {
        var ageDifMs = Date.now() - birthday.getTime();
        var ageDate = new Date(ageDifMs); // miliseconds from epoch
        var anios = Math.abs(ageDate.getUTCFullYear() - 1970);
        return anios;
    }


    //console.log(riguroso);
  

    return (
        statePaint &&
        <Row>
            {
                dataSample !== null &&
                <Col sm="12">
                    <Row>
                        <Col sm="6">
                            <Card className="shadow">
                                <CardBody className="p-3">

                                    <div className='ribbon ribbon-clip ribbon-primary'>
                                        DATOS DE LA MUESTRA - {dataSample.folio}
                                    </div>
                                    <br />
                                    <br />
                                    {
                                        dataSample.patient !== null ?
                                            <Table>
                                                <tbody>
                                                    <tr style={{ fontSize: "8px" }}>
                                                        <th>TIPO DE MUESTRA</th>
                                                        <th>PACIENTE</th>
                                                        {/* <th>SITIO</th> */}
                                                        <th>AREA</th>
                                                        <th>TEMPERATURA</th>
                                                        <th>CANTIDAD</th>
                                                        <th>OBSERVACIONES</th>
                                                    </tr>
                                                    <tr style={{ fontSize: "10px" }}>
                                                        <td>{dataSample.type_sample}</td>
                                                        <td>{dataSample.patient}</td>
                                                        {/* <td>{dataSample.site}</td> */}
                                                        <td>{dataSample.area}</td>
                                                        <td>{dataSample.temperature}</td>
                                                        <td>{dataSample.quantity}</td>
                                                        <td>{dataSample.observations}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                            :
                                            <Table>
                                                <tbody>
                                                    <tr style={{ fontSize: "8px" }}>
                                                        <th>TIPO DE MUESTRA</th>
                                                        <th>MUESTRA</th>
                                                        {/* <th>SITIO</th> */}
                                                        <th>AREA</th>
                                                        <th>TEMPERATURA</th>
                                                        <th>CANTIDAD</th>
                                                        <th>OBSERVACIONES</th>
                                                    </tr>
                                                    <tr style={{ fontSize: "10px" }}>
                                                        <td>{dataSample.type_sample}</td>
                                                        <td>{dataSample.sample}</td>
                                                        {/* <td>{dataSample.site}</td> */}
                                                        <td>{dataSample.area}</td>
                                                        <td>{dataSample.temperature}</td>
                                                        <td>{dataSample.quantity}</td>
                                                        <td>{dataSample.observations}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm="6">
                            <Card className="shadow">
                                <CardBody className="p-3">

                                    <div className='ribbon ribbon-clip ribbon-primary'>
                                        DATOS DE RECEPCIÓN - {dataSample.folio}
                                    </div>
                                    <br />
                                    <br />
                                    {
                                        dataSample.patient !== null ?
                                            <Table>
                                                <tbody>
                                                    <tr style={{ fontSize: "8px" }}>
                                                        <th>TEMP DE REC</th>
                                                        <th>TEMP COR</th>
                                                        <th>CUMPLE TEMP</th>
                                                        <th>IDENTIFICACIÓN</th>
                                                        <th>CONTENEDOR</th>
                                                        <th>CANTIDAD</th>
                                                        <th>OBSERVACIONES</th>
                                                    </tr>
                                                    <tr style={{ fontSize: "10px" }}>
                                                        <td>{dataSample.temperature_send}</td>
                                                        <td>{dataSample.temperature_corrected_send}</td>
                                                        <td>{dataSample.comply_temperature_send}</td>
                                                        <td>{dataSample.identification_send}</td>
                                                        <td>{dataSample.container_send}</td>
                                                        <td>{dataSample.quantity_send}</td>
                                                        <td>{dataSample.comments}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                            :
                                            <Table>
                                                <tbody>
                                                    <tr style={{ fontSize: "8px" }}>
                                                        <th>TEMP DE REC</th>
                                                        <th>TEMP COR</th>
                                                        <th>CUMPLE TEMP</th>
                                                        <th>IDENTIFICACIÓN</th>
                                                        <th>CONTENEDOR</th>
                                                        <th>CANTIDAD</th>
                                                        <th>OBSERVACIONES</th>
                                                    </tr>
                                                    <tr style={{ fontSize: "10px" }}>
                                                    <td>{dataSample.temperature_send}</td>
                                                        <td>{dataSample.temperature_corrected_send}</td>
                                                        <td>{dataSample.comply_temperature_send}</td>
                                                        <td>{dataSample.identification_send}</td>
                                                        <td>{dataSample.container_send}</td>
                                                        <td>{dataSample.quantity_send}</td>
                                                        <td>{dataSample.comments}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            }
            <Col sm="12">
                <Card className="shadow">
                    <CardBody className="p-3">
                        <Row>
                            <Col sm="4" className='' >
                                <h6>{examen.name} </h6>
                            </Col>
                            <Col sm="3">
                                {
                                    (enable_interface_imagenology && examen.is_imagenology)
                                        ?
                                        examen.release_date === null ? "En espera de resultados" : "Resultados liberados"
                                        :
                                        (!enable_interface_imagenology && !examen.is_imagenology)
                                            ?
                                            (canReport(examen.id_branch))
                                                ?
                                                <Input type='select' tabIndex="-1" className="form-control form-control-sm" onChange={changeMethod} disabled={examen.method_selected > 0 ? true : false} value={appCodeOrMethodExam}>
                                                    {
                                                        examen.methods.map((metodo, key3) => {
                                                            return <option key={key3} value={metodo.id_exam_method}>Método: {metodo.name} {metodo.name_machine !== "" ? `(${metodo.name_machine})` : ""}</option>
                                                        })
                                                    }
                                                </Input>
                                                :
                                                examen.is_internal_maquila
                                                    ?
                                                    examen.isRelease
                                                        ?
                                                        <Input type='select' tabIndex="-1" className="form-control form-control-sm" onChange={changeMethod} disabled={examen.method_selected > 0 ? true : false} value={appCodeOrMethodExam}>
                                                            {
                                                                examen.methods.map((metodo, key3) => {
                                                                    return <option key={key3} value={metodo.id_exam_method}>Método: {metodo.name} {metodo.name_machine !== "" ? `(${metodo.name_machine})` : ""}</option>
                                                                })
                                                            }
                                                        </Input>
                                                        : <p>El examen esta siendo procesado en {examen.name_branch}</p>
                                                    : <p>No tienes acceso para capturar el resultado</p>
                                            : ""
                                }
                            </Col>
                            <Col sm="5" className='text-right'>
                                {
                                    (!enable_interface_imagenology && (!examen.is_imagenology))
                                        ?
                                        (canReport(examen.id_branch))
                                            ?
                                            <ButtonGroup tabIndex="-1">
                                                {
                                                    releaseData === null
                                                        ?
                                                        !readyToRelease && (
                                                            <Button id='tooltipValidate' tabIndex="-1" className={!readyToValidate ? "d-none" : ""} outline color='success' onClick={readyToValidate ? () => validateExam(examen.id_work_order_exam, examen.id_exam, typeArray, sectionProfileId) : null}>Validar prueba</Button>
                                                        )
                                                        : ""
                                                }
                                                {
                                                    readyToValidate
                                                        ? ""
                                                        :
                                                        <Tooltip
                                                            isOpen={tooltipOpen}
                                                            target="tooltipValidate"
                                                            toggle={toggleTooltip}
                                                        >
                                                            Guarde todos los resultados en rojo para poder validar toda la prueba
                                                        </Tooltip>
                                                }
                                                {
                                                    releaseData === null 
                                                        ?
                                                        (readyToRelease && <>
                                                            <Button tabIndex="-1" className={riguroso==true?'d-none':""} onClick={() => removeValidationFromExamOrProfile(examen.id_work_order_exam)} color='danger' outline>Desvalidar</Button>
                                                            <Button tabIndex="-1" className={riguroso==true?'d-none':""} onClick={() => releaseExam(examen.id_work_order_exam, examen.id_exam)} color='secondary'>Liberar prueba</Button>
                                                        </>)
                                                        :
                                                        <>
                                                            <Button tabIndex="-1"  onClick={() => removeValidationFromExamOrProfile(examen.id_work_order_exam)} color='danger' outline>Desvalidar</Button>
                                                            <span style={{ display: "flex", alignItems: "center" }} className='badge badge-light-primary'><h6 className='p-0 m-0'>PRUEBA LIBERADA</h6></span>
                                                        </>
                                                }
                                            </ButtonGroup>
                                            :
                                            (releaseData === null)
                                                ?
                                                !readyToRelease ? <p>En espera de validación</p> : <p>En espera de liberación</p>
                                                : <h5>PRUEBA LIBERADA</h5>
                                        : (enable_interface_imagenology && (!examen.is_imagenology)) ?
                                            (canReport(examen.id_branch))
                                                ?
                                                <ButtonGroup tabIndex="-1">
                                                    {
                                                        releaseData === null
                                                            ?
                                                            !readyToRelease && (
                                                                <Button id='tooltipValidate' tabIndex="-1" className={!readyToValidate ? "d-none" : ""} outline color='success' onClick={readyToValidate ? () => validateExam(examen.id_work_order_exam, examen.id_exam, typeArray, sectionProfileId) : null}>Validar prueba</Button>
                                                            )
                                                            : ""
                                                    }
                                                    {
                                                        readyToValidate
                                                            ? ""
                                                            :
                                                            <Tooltip
                                                                isOpen={tooltipOpen}
                                                                target="tooltipValidate"
                                                                toggle={toggleTooltip}
                                                            >
                                                                Guarde todos los resultados en rojo para poder validar toda la prueba
                                                            </Tooltip>
                                                    }
                                                    {
                                                        releaseData === null
                                                            ?
                                                            (readyToRelease && <Button tabIndex="-1" className={riguroso==true?'d-none':""} onClick={() => releaseExam(examen.id_work_order_exam, examen.id_exam)} color='secondary'>Liberar prueba</Button>)
                                                            : <h5>PRUEBA LIBERADA</h5>
                                                    }
                                                </ButtonGroup>
                                                :
                                                (releaseData === null)
                                                    ?
                                                    !readyToRelease ? <p>En espera de validación</p> : <p>En espera de liberación</p>
                                                    : <h5>PRUEBA LIBERADA</h5>
                                            : ""
                                }
                            </Col>
                          
                        </Row>
                       
                        {
                            canReport(examen.id_branch) && examMethod > 0 &&
                            ((curves === null || curves === undefined) ?
                                <>
                                    {
                                        examen.analytes.map((analyte, key4) => {
                                            return <InputAnalyte
                                                analyteData={analytes.find(x => x.id == analyte.id_analyte) !== undefined ? analytes.find(x => x.id == analyte.id_analyte) :
                                                    {
                                                        "id": "",
                                                        "capture": "",
                                                        "id_exam": "",
                                                        "id_type_analyte": "",
                                                        "id_exam_method": "",
                                                        "decimals": "",
                                                        "compute": "",
                                                        "variable": "",
                                                        "comment": ""
                                                    }
                                                }
                                                changeMethod={changeMethod}
                                                dataExam={examen}
                                                validateAnalyte={validateAnalyte}
                                                releaseData={releaseData}
                                                saveIndividualAnalyte={saveIndividualAnalyte} examMethod={examMethod} appCodeOrMethodExam={appCodeOrMethodExam} age={ageSample} days_age={ageDaysSample} gender={idGenderSample} analyte={analyte} key={key4} idPat={idPat} namePatiente={namePatiente} nameP={nameP} surnameP={surnameP} surnameM={surnameM}
                                                allCurves={"Esta entrando por aca"}
                                                previewWorkOrder={previewWorkOrder}
                                            />
                                        })
                                    }
                                    
                                </>
                                :
                                examen.analytes.map((analyte, key4) => {
                                    return <InputAnalyte
                                        analyteData={analytes.find(x => x.id == analyte.id_analyte) !== undefined ? analytes.find(x => x.id == analyte.id_analyte) :
                                            {
                                                "id": "",
                                                "sub_id": curves.find(x => x.time.value === analyte.sub_id).time.value,
                                                "capture": "",
                                                "id_exam": "",
                                                "id_type_analyte": "",
                                                "id_exam_method": "",
                                                "decimals": "",
                                                "compute": "",
                                                "variable": "",
                                                "comment": ""
                                            }
                                        }
                                        changeMethod={changeMethod}
                                        dataExam={examen}
                                        validateAnalyte={validateAnalyte}
                                        releaseData={releaseData}
                                        saveIndividualAnalyte={saveIndividualAnalyte} examMethod={examMethod} appCodeOrMethodExam={appCodeOrMethodExam} age={ageSample} days_age={ageDaysSample} gender={idGenderSample} analyte={analyte} key={key4} idPat={idPat} namePatiente={namePatiente} nameP={nameP} surnameP={surnameP} surnameM={surnameM}
                                        dataCurve={curves.find(x => x.time.value === analyte.sub_id)}
                                    />
                                })
                            )
                        }
                        {
                            !canReport(examen.id_branch) && examen.isRelease &&
                            ((curves === null || curves === undefined) ?
                                <>
                                    {
                                        examen.analytes.map((analyte, key4) => {
                                            return <InputAnalyte
                                                analyteData={analytes.find(x => x.id == analyte.id_analyte) !== undefined ? analytes.find(x => x.id == analyte.id_analyte) :
                                                    {
                                                        "id": "",
                                                        "capture": "",
                                                        "id_exam": "",
                                                        "id_type_analyte": "",
                                                        "id_exam_method": "",
                                                        "decimals": "",
                                                        "compute": "",
                                                        "variable": "",
                                                        "comment": ""
                                                    }
                                                }
                                                changeMethod={changeMethod}
                                                dataExam={examen}
                                                validateAnalyte={validateAnalyte}
                                                releaseData={releaseData}
                                                saveIndividualAnalyte={saveIndividualAnalyte} examMethod={examMethod} appCodeOrMethodExam={appCodeOrMethodExam} age={ageSample} days_age={ageDaysSample} gender={idGenderSample} analyte={analyte} key={key4} idPat={idPat} namePatiente={namePatiente} nameP={nameP} surnameP={surnameP} surnameM={surnameM}
                                                allCurves={"Esta entrando por aca"}
                                            />
                                        })
                                    }
                                   
                                </>
                                :
                                examen.analytes.map((analyte, key4) => {
                                    return <InputAnalyte
                                        analyteData={analytes.find(x => x.id == analyte.id_analyte) !== undefined ? analytes.find(x => x.id == analyte.id_analyte) :
                                            {
                                                "id": "",
                                                "sub_id": curves.find(x => x.time.value === analyte.sub_id).time.value,
                                                "capture": "",
                                                "id_exam": "",
                                                "id_type_analyte": "",
                                                "id_exam_method": "",
                                                "decimals": "",
                                                "compute": "",
                                                "variable": "",
                                                "comment": ""
                                            }
                                        }
                                        changeMethod={changeMethod}
                                        dataExam={examen}
                                        validateAnalyte={validateAnalyte}
                                        releaseData={releaseData}
                                        saveIndividualAnalyte={saveIndividualAnalyte} examMethod={examMethod} appCodeOrMethodExam={appCodeOrMethodExam} age={ageSample} days_age={ageDaysSample} gender={idGenderSample} analyte={analyte} key={key4} idPat={idPat} namePatiente={namePatiente} nameP={nameP} surnameP={surnameP} surnameM={surnameM}
                                        dataCurve={curves.find(x => x.time.value === analyte.sub_id)}
                                    />
                                })
                            )
                        }
                    </CardBody>
                </Card>
            </Col>
        </Row >
    )
}