import React, { } from 'react';
import { Button, ButtonGroup, Table, Input} from 'reactstrap';
import { MultiSelect } from 'primereact/multiselect';
import '../../../../hooks/componentMultiSelect/MultiSelectDemo.css';
import './styleGrid.css';

export const DataGridComponent = (props) => {

  const {checkTypeClient,indexs,handleObservations,id_type_sample,listRules,handleSearchRules,_key,handleRemoveDeterminacion,handleViewExamsProfile,handleDiscount} = props;

  return (
    <div>
      <Table className='table table-borderless'>
        <thead className="border-top-info">
          <tr className='border-bottom-info' >
            <th style={{ textAlign: "-webkit-center",color:"#0079C7"} }> <b>Determinación</b> </th>
            <th style={{ width: "10px", textAlign: "-webkit-center" ,color:"#0079C7"}}> <b>Metodología</b> </th>
            <th className={checkTypeClient === 1 ? "" : "d-none"} style={{ width: "10px", textAlign: "-webkit-center",color:"#0079C7" }} ><b>Observaciones</b></th>
            <th style={{ width: "10px",color:"#0079C7" }}><b>Abreviatura</b> </th>
            <th className={checkTypeClient === 1 ? "d-none" : ""} style={{ width: "10px",color:"#0079C7" }}><b>Convenio</b> </th>
            <th style={{ width: "10px", textAlign: "-webkit-center",color:"#0079C7" }}> <b>Costo</b> </th>
            <th className={checkTypeClient === 1 ? "" : "d-none"} style={{ width: "10px",color:"#0079C7" }}><b>Descuento</b> </th>
            <th style={{textAlign: "-webkit-center" ,color:"#0079C7"}} ><b>Acciones</b> </th>
          </tr>
        </thead>
        <tbody className="f-13 text-left border-bottom-info" style={{ paddingBottom: 30 }}>
          {
            indexs.length >= 1  && indexs.map((_find,_key1) => {

              return(
                <tr key={_key1} className='border-bottom-info'>
                  <td  style={ checkTypeClient === 1 ? { verticalAlign: "middle", width: "20%" } : { verticalAlign: "middle", width: "28%" } }  > <label style={{fontSize:"11px"}}>{_find?.name ?? "----"} </label></td>
                  <td style={{ textAlign: "-webkit-center", width: "10px" }}>
                  <MultiSelect
                    className={`basic-multi-select input-air-primary form-control-sm custom-multi-select`}
                    value={_find.list_nom}
                    options={listRules}
                    onChange={(e) => handleSearchRules(e, _find?.id_exam, _find?.id_profile, _key)}
                    optionLabel="name"
                    placeholder={"Selecciona una Norma"}
                    maxSelectedLabels={3}
                    selectedItemsLabel="Secciones Agregadas"
                    filter
                    style={{fontSize:"10px"}}
                />
                  </td>
                  <td style={{ verticalAlign: "middle", width: "18%" }} className={checkTypeClient === 1 ? "" : "d-none"} >
                  <textarea
                          name="observations_det"
                          className='form-control form-control-sm input-air-primary'
                          rows="2"
                          onChange={(e) => handleObservations(e, _find?.id_exam, _find?.id_profile, id_type_sample)}
                          value={_find?.observations_det}
                          style={{fontSize:"11px"}}
                        >
                  </textarea>
                  </td>
                  <td style={ checkTypeClient === 1 ?  { verticalAlign: "middle", width: "10%" } : { verticalAlign: "middle", width: "14%" }}><label style={{fontSize:"11px"}}>{_find?.abbreviation ?? "----"}</label> 
                    {
                      _find.id_profile !== null ?
                          <>
                          {
                            _find.id_profile !== null ? <Button size='xs' color='primary' onClick={() => handleViewExamsProfile(_key, _find.id_profile)}><i className="icofont icofont-laboratory"></i></Button> : ""
                          }
                          </>
                          :
                          <>
                          <label style={{fontSize:"11px"}}>{_find.abbreviation}</label>
                          </>
                      }
                  </td>
                  <td style={{ verticalAlign: "middle", width: "10%" }} className={checkTypeClient === 1 ? "d-none" : ""}> <label className='txt-primary f-w-600'> {_find?.contract_number ?? "----"}</label>
                  </td>
                  <td style={{ verticalAlign: "middle", width: "8%" }}> <label className='f-w-500' style={{fontSize:"11px"}}>${_find?.price.toFixed(2) ?? 0}</label></td>
                  <td style={{ verticalAlign: "middle", width: "8%" }} className={checkTypeClient === 1 ? "" : "d-none"}>
                  <Input
                      type='number'
                      className="form-control"
                      name="discount_par"
                      value={_find?.discount_par}
                      onChange={(e) => handleDiscount(e, _find?.id_exam, _find?.id_profile, _key)}
                  />
                  </td>
                 
                  <td style={{ verticalAlign: "middle", width: "1%" }}>
                  <ButtonGroup>
                    <Button
                        className="btn-air-danger"
                        outline color="danger"
                        size="xs"
                        key={_key}
                        onClick={() => handleRemoveDeterminacion(_key, _find?.id_exam, _find?.id_profile, id_type_sample)}>
                        <i className="fa fa-trash-o"></i>
                    </Button>
                  </ButtonGroup>
                  </td>
                </tr>

              )

            })
           

          }


        </tbody>
      </Table>
    </div>
  )
}
