import React from 'react'
import { Gramm,Microorganism,Cuantity_Number,Cuantity_Text,ResistanceMechanism,Comments} from '../../../constant/globalContantsMicro';
import { Col, Input, Row,Button} from 'reactstrap'
import Select from 'react-select';
import { ToolTips } from '../../../components/tooltips/ToolTips';
import UserValidate from '../../../assets/images/loginHelenLabs/Dicount/UserDiscount2.svg'
import { Both,Other,Actibiogram,Isolated,placeholder_stain_gram,placeholder_bacterium,placeholder_count_text,placeholder_mechanisms,validateBacter,unValidateBacter,unReleaseteBacter,LabelSelectionMicro,LabelSelectionAntibiogram,Labelmedicamen,LabelInterpretation,LabelCm,LabelAntibitic,placeholder_anti,ReleaseteBacter} from '../../../constant/globalContantsMicro';


export const ComponentMicroorganismIsolated = ({idExam,microBacteriums,handleChangeBacter,listStainGrams,listOptionsBacteriums,handleGram,listBacteriumCount,listResistanceMechanism,colorBacter,warningBacter,handleTypeBacterium,microAntibiograms,changeMicro,listAntibiogram,handleChange,antibitic,handleChangeAntibiogram,handleChangeComments,handleChangeBacteriumTexts,removeBacterium1,removeBacteriumAntibitic,ValidateBacterium,examId,is_profile,sectionProfileId,typeArray,UnValidateBacterium,ReleaseBacterium,UnReleaseBacterium}) => {
    return (
        <>
        <Col sm="11" className='p-r-0' >
            {microBacteriums?.id_work_order_exam === idExam && (
                <div style={{boxShadow:"0 0 40px rgba(8, 21, 66, 0.05)"}} key={`id_mic-${microBacteriums?.id_temp}`}>
                <div style={{ borderTop: "4px dotted #28a745", padding: "10px", backgroundColor: "transparent" }}></div>
                <h5>{Isolated}</h5>
                <Col sm="10" className='p-r-0 p-l-0' >
                <Row>
                        <Col sm="3">
                            <label className='txt-secondary'>{Gramm}</label>
                            <Select
                                isDisabled={microBacteriums?.validated_at !== "" ? true :false}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                menuPlacement={"top"}
                                name="id_mic_stain_gram"
                                options={listStainGrams}
                                value={microBacteriums?.id_mic_stain_gram}
                                styles={microBacteriums?.id_mic_stain_gram === "" ? warningBacter : colorBacter}
                                onChange={(e) => handleGram(e, microBacteriums?.id_temp, "id_mic_stain_gram", idExam, microBacteriums?.id_mic_bacterium)}
                                placeholder={placeholder_stain_gram}
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#898989',
                                    },
                                })}
                            />
                        </Col>
                        <Col sm="3">
                            <label className='txt-secondary'>{Microorganism}</label>
                            <Select
                                isDisabled={microBacteriums?.validated_at !== "" ? true :false}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                menuPlacement={"top"}
                                name="id_mic_bacterium"
                                options={listOptionsBacteriums}
                                value={microBacteriums?.id_mic_bacterium}
                                styles={microBacteriums?.id_mic_bacterium === "" ? warningBacter : colorBacter}
                                onChange={(e) => handleChangeBacter(e, microBacteriums?.id_temp, "id_mic_bacterium", idExam, microBacteriums?.id_mic_bacterium)}
                                placeholder={placeholder_bacterium}
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#898989',
                                    },
                                })}
                            />
                        </Col>
                        <Col sm="3">
                            <label className='txt-secondary'>{Cuantity_Number}</label>
                            <Input
                                disabled={microBacteriums?.validated_at !== "" ? true : false}
                                className='form-control form-control-sm input-air-primary border-success'
                                name='quantity_number'
                                value={microBacteriums?.quantity_number}
                                onChange={(e) => handleChangeBacter(e, microBacteriums?.id_temp, "quantity_number", idExam, microBacteriums?.quantity_number)}
                            />
                        </Col>
                        <Col sm="3" className='p-r-0' >
                            <label className='txt-secondary'>{Cuantity_Text}</label>
                            <Select
                                isDisabled={microBacteriums?.validated_at !== "" ? true :false}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                menuPlacement={"top"}
                                name="id_bacterium_count_text"
                                options={listBacteriumCount}
                                value={microBacteriums?.id_bacterium_count_text}
                                styles={colorBacter}
                                onChange={(e) => handleChangeBacter(e, microBacteriums?.id_temp, "id_bacterium_count_text", idExam, microBacteriums?.id_bacterium_count_text)}
                                placeholder={placeholder_count_text}
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#898989',
                                    },
                                })}
                            />
                        </Col>
                        </Row>
                </Col>
                        
                    <Row className='mt-4'>
                        <Col sm="10" className="p-r-0" >
                        <h5>{ResistanceMechanism}</h5>
                            <label className='txt-secondary'>{ResistanceMechanism}</label>
                            <Select
                                isDisabled={microBacteriums?.validated_at !== "" ? true :false}
                                isMulti
                                menuPlacement={"top"}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                name="ids_resistance_mechanisms"
                                options={listResistanceMechanism}
                                value={microBacteriums?.ids_resistance_mechanisms}
                                styles={colorBacter}
                                onChange={(e) => handleChangeBacter(e, microBacteriums?.id_temp, "ids_resistance_mechanisms", idExam, microBacteriums?.ids_resistance_mechanisms)}
                                placeholder={placeholder_mechanisms}
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#898989',
                                    },
                                })}
                            />
                        </Col>
                        <Col  sm="2" className='p-r-0 pt-4' >
                        {
                            // * CONFIGURATION ACTIONS
                        }
                        <Row className='pt-4' > 
                            {
                                // * CONFIGURATION USERS VALIDATIONS
                            }
                            <Col sm="2" className='pt-3' >
                            {
                                (microBacteriums?.validated_at !== "" && microBacteriums?.released_at === "" ) &&
                                <>
                                <img id={"tooltip-bacterium-" + microBacteriums?.id_temp} style={{ cursor: "pointer"  , width: "32px"}} className=" mr-1 rounded-circle" src={ UserValidate} alt=""/>
                                    <ToolTips
                                        dataPlacement='top'
                                        dataTarget={"tooltip-bacterium-" + microBacteriums?.id_temp}
                                        dataText={"-"+microBacteriums?.validated_at+ "-" + "Capturado por:" + microBacteriums?.validated_by}
                                    />
                                </>
                            }
                            {
                                (microBacteriums?.validated_at !== "" && microBacteriums?.released_at !== "" ) &&
                                <>
                                <img id={"tooltip-bacterium-release-" + microBacteriums?.id_temp} style={{ cursor: "pointer"  , width: "32px"}} className=" mr-1 rounded-circle" src={ UserValidate} alt=""/>
                                    <ToolTips
                                        dataPlacement='top'
                                        dataTarget={"tooltip-bacterium-release-" + microBacteriums?.id_temp}
                                        dataText={"-"+microBacteriums?.released_at+ "-" + "Capturado por:" + microBacteriums?.released_by}
                                    />
                                </>
                            }
                            {
                                // *DELETE BACTERIUM
                            }
                            {   
                                microBacteriums?.validated_at === "" && <i className=" fa fa-trash-o text-danger f-26 pointer " onClick={() =>removeBacterium1(idExam,microBacteriums?.id_temp,microBacteriums?.id_mic_bacterium)}></i>
                            }
                            </Col>
                            {
                                // * CONFIGURATION  RELEASE BACTERIUM AND VALIDATE BACTERIUM
                            }
                            <Col sm="4" className='pt-4' >  
                            {
                                microBacteriums?.validated_at === "" && microBacteriums?.update  
                                ?
                                <Button tabIndex="-1" size='xs' color='primary' onClick={() =>ValidateBacterium(idExam,microBacteriums?.id_temp,microBacteriums?.id_mic_bacterium,examId,is_profile,sectionProfileId,typeArray,microBacteriums?.id_mic_bacterium)} outline >{validateBacter}</Button>
                                :
                                ( microBacteriums?.released_at === "" && microBacteriums.validated_at  !== "") 
                                ?
                                <Button tabIndex="-1" size='xs' color='danger' onClick={() => UnValidateBacterium(idExam,microBacteriums?.id_temp,microBacteriums?.id_mic_bacterium,examId,is_profile,sectionProfileId,typeArray)} outline >{unValidateBacter}</Button>
                                :
                                ""
                            }     
                            {
                                microBacteriums?.released_at !== "" 
                                ? 
                                <Button tabIndex="-1" size='xs' color='danger' onClick={() => UnReleaseBacterium(idExam,microBacteriums?.id_temp,microBacteriums?.id_mic_bacterium,examId,is_profile,sectionProfileId,typeArray)} outline >{unValidateBacter}</Button>
                                : 
                                ""
                            }                     
                           </Col>
                           <Col sm="2" className='pt-4' >
                            {
                                microBacteriums?.released_at === "" && microBacteriums?.validated_at !== "" 
                                ?
                                <Button tabIndex="-1" size='xs' color='success' onClick={() =>ReleaseBacterium(idExam,microBacteriums?.id_temp,microBacteriums?.id_mic_bacterium,examId,is_profile,sectionProfileId,typeArray,microBacteriums?.id_mic_bacterium)} outline >{ReleaseteBacter}</Button>
                                : 
                                ""
                           }
                           {
                                microBacteriums?.released_at !== "" && microBacteriums?.validated_at !== "" 
                                ? 
                                <label className='badge badge-light-primary f-12'>{unReleaseteBacter}</label>
                                :
                                ""
                           }
                           </Col>
                        </Row>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col sm="10" className='p-r-0' >
                            <label className='txt-secondary'>{Comments}</label>
                            <Input
                                disabled={microBacteriums?.validated_at !== "" ? true :false}
                                className='form-control form-control-sm input-air-primary border-success'
                                name='comment_resistance_mechanism'
                                value={microBacteriums?.comment_resistance_mechanism}
                                onChange={(e) => handleChangeBacter(e, microBacteriums?.id_temp, "comment_resistance_mechanism", idExam, microBacteriums?.comment_resistance_mechanism)}
                            />
                        </Col>
                    </Row>
                        <Row className='pt-3' style={microBacteriums?.validated_at !== "" ? { pointerEvents: "none", opacity: "0.5" } : {}}>
                        <Col sm="7" className='mt-3' >
                        <h5>{Actibiogram}</h5>
                        </Col>
                        <Col sm="1" className='p-l-0 p-r-0 mt-3' >
                        <i className={microBacteriums?.view2 === true ? "fa fa-square f-20 icon-a-a" : "fa fa-square-o f-20 icon-a-a"}  onClick={() => handleTypeBacterium(2,microBacteriums?.id_temp,idExam,microBacteriums?.id_mic_bacterium)}  ></i>
                        <label className='txt-success'>{Other}</label>
                        </Col>
                        <Col sm="1" className='p-l-0 p-r-0 mt-3' style={{textAlign: "end"}}>
                        <i className={microBacteriums?.view1 === true ? "fa fa-square f-20 icon-a-a" : "fa fa-square-o f-20 icon-a-a"} onClick={() => handleTypeBacterium(1,microBacteriums?.id_temp,idExam,microBacteriums?.id_mic_bacterium)}  ></i>
                        <label className='txt-success'>{Actibiogram}</label>
                        </Col>
                        <Col sm="1" className='p-l-0 p-r-0 mt-3' style={{textAlign: "end"}} >
                        <i className={microBacteriums?.view3 === true ? "fa fa-square f-20 icon-a-a" : "fa fa-square-o f-20 icon-a-a"} onClick={() => handleTypeBacterium(3,microBacteriums?.id_temp,idExam,microBacteriums?.id_mic_bacterium)}  ></i>
                        <label className='txt-success'>{Both}</label>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col sm="11">
                        {
                            (microBacteriums?.view1 || microBacteriums?.view3 ) && <div>
                            <Col sm="12" className='p-l-0 p-r-0' >
                            <Row>
                                <Col sm="6">
                                <b className='txt-success'>{LabelSelectionMicro}</b> <br />
                                <Select
                                isDisabled={microBacteriums?.validated_at !== "" ? true :false}
                                menuPlacement={"top"}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                name="id_mic_bacteriums"
                                options={[microBacteriums?.id_mic_bacterium]}
                                value={microBacteriums?.update ? microBacteriums?.id_mic_bacterium : microAntibiograms?.id_mic_bacterium   }
                                onChange={(e) => changeMicro(e, microBacteriums?.id_temp,idExam)}
                                placeholder={"Selecciona un microorganismo"}
                                styles={colorBacter}
                                theme={
                                    (theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#898989'
                                        }
                                    })
                                }
                            />
                                </Col>
                                <Col sm="6">
                                <b className='txt-success'>{LabelSelectionAntibiogram}</b> <br />
                                <Select
                                isDisabled={microBacteriums?.validated_at !== "" ? true :false}
                                menuPlacement={"top"}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                name="id_mic_antibiogram"
                                options={listAntibiogram}
                                value={microBacteriums?.id_mic_antibiogram}
                                onChange={(e) => handleChangeAntibiogram(e, microBacteriums?.id_temp, "id_mic_antibiogram", idExam, microBacteriums?.id_mic_antibiogram,"section")}
                                placeholder={placeholder_anti}
                                styles={colorBacter}
                                theme={
                                    (theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#898989'
                                        }
                                    })
                                }
                            />
                                </Col>
                            </Row>      
                            </Col>  
                            </div>
                        }
                        {
                            (microBacteriums?.view3 || microBacteriums?.view1) && microBacteriums?.antibiotics.length > 0 &&
                            <div className='pt-3 pricingtable' style={{textAlign:"justify"}}  >  
                                <Col sm="12" className='p-l-0 p-r-0 pt-3' >
                                <Row className='pt-3 ' >
                                    <Col sm="3">
                                    <b>{Labelmedicamen}</b>
                                    </Col>
                                    <Col sm="2">
                                    <b>{LabelCm}</b>
                                    </Col>
                                    <Col sm="7" style={{textAlign:"center"}} >
                                    <b>{LabelInterpretation}</b>
                                    </Col>
                                </Row>
                                </Col>
                                {
                                    microBacteriums?.antibiotics.map((_anti, _key) => {
                                        return (
                                            <Row className='pt-3' key={`id_antibiogram-${_key}`} >
                                                <Col sm="3">
                                                    <label className='txt-success'> { _anti?.mic_antibiotic ?? "---"}</label>
                                                </Col>
                                                <Col sm="1" className='p-l-0 ' >
                                                    <Input
                                                        disabled={microBacteriums?.validated_at !== "" ? true :false}
                                                        className='form-control form-control-sm input-air-primary border-success'
                                                        type='text'
                                                        name='cim'
                                                        value={_anti?.cim}
                                                        onChange={(e) => handleChange(e, microBacteriums?.id_temp, idExam, _anti?.cim,_anti?.id_mic_antibiotic,"cim")}
                                                    />
                                                </Col>
                                                <Col sm="8">
                                                    <Row>
                                                        {
                                                            antibitic.length > 0 && antibitic.map((_antibiotic, _keys) => {
                                                                const _isCheck =  _antibiotic?.id_antibiotic_bacterium_result === _anti?.id_antibiotic_bacterium_result;
                                                                return (
                                                                    <Col sm="3" key={_keys}>
                                                                        <label style={{ color: _isCheck ? 'green' : 'black' }}>
                                                                            <input
                                                                                disabled={microBacteriums.validated_at !== "" ? true :false}
                                                                                type="checkbox"
                                                                                value={_antibiotic?.id_mic_antibiotic}
                                                                                checked={_isCheck}
                                                                                onChange={(e) => handleChange(e, microBacteriums?.id_temp, idExam, _antibiotic?.id_antibiotic_bacterium_result, _anti?.id_mic_antibiotic,"check")}
                                                                                style={{ accentColor: 'green' }} 
                                                                            />
                                                                            {_antibiotic.name}
                                                                        </label>
                                                                    </Col>
                                                                );
                                                            })
                                                        }
                                                    </Row>
                                                </Col>
                                            </Row>
                                        );
                                    })
                                }
                            </div>
                        }
                        {
                           (microBacteriums?.view3 || microBacteriums?.view2 )&& microBacteriums?.bacterium_texts.length > 0 && 
                            <div className='pt-3 pricingtable pl-0 pr-0' style={{textAlign:"justify"}}>
                                {
                                    microBacteriums?.bacterium_texts.map((_text,_k) => {
                                        return(
                                            <Row key={_k} >
                                                <Col sm="1" className='p-r-0'>
                                                <label className='txt-secondary'>{LabelAntibitic}</label>
                                                </Col>
                                                <Col sm="10" className='p-l-0' >
                                                    <Input
                                                        disabled={microBacteriums.validated_at !== "" ? true :false}
                                                        type='text'
                                                        name="text"
                                                        className='form-control form-control-sm input-air-primary border-success input-mic'
                                                        value={_text?.text}
                                                        onChange={(e) => handleChangeBacteriumTexts(e,microBacteriums?.id_temp, idExam, _text?.text,microBacteriums?.id_mic_bacterium,"text",_text?.id)}
                                                    /> <br />
                                                </Col>
                                                <Col sm="1" className={microBacteriums.validated_at !== "" ? "d-none" : ""}  >
                                                    <i  onClick={() => handleChangeComments(2,microBacteriums?.id_temp,idExam,microBacteriums?.id_mic_bacterium)}   style={{ color: "#28a745" }} className={_k === 0 ? "fa fa-plus-circle f-28 pointer" : "d-none"}></i>
                                                    <i  onClick={() =>removeBacteriumAntibitic(idExam,_text?.id,microBacteriums?.id_mic_bacterium,microBacteriums?.id_temp)}   className="fa fa-trash-o text-danger f-26 pointer"></i>
                                                </Col>
                                            </Row>
                                        )
                                    })
                                    
                                }
                            </div>
                        }
                        </Col>
                    </Row>
                </div>
            )}
        </Col>
        </>
    )
}
