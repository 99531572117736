import React from 'react'
import { Tinción, Result, Microorganism, Observations, Time, MessageDelete,} from '../../../constant/globalContantsMicro';
import { Col, Input, Row,Button} from 'reactstrap'
import Select from 'react-select';
import UserValidate from '../../../assets/images/loginHelenLabs/Dicount/UserDiscount2.svg'
import { ToolTips } from '../../../components/tooltips/ToolTips';


export const ComponentPresumtiveResult = ({ presumptive, handleChangePresumptive, addPresumptiveResult, dataPrecodText, removePresumptiveResult, AlertItem, deletePresuntive, dataBacteriums, idExam, dataStain, savePresuntive,newPresuntive,saveIPresuntive,PrintingPresumptiveResult,cancelRemovePresumtiveResults,cancelPrintingPresumptive,valdatePresumtiveResults,ReleasePresumtiveResults,unvalidatePresumtiveResults,unreleasePresumtiveResults,typeArray,examId,sectionProfileId}) => {
    return (
        <>
            <Col sm="12" >
                {
                    presumptive.length > 0 && presumptive[0]?.presumptives?.map((_a,_key) => {
                        let _validate =(_a.user_validate !== null && _a.id_presumptive_result !== null) ? true : false
                        return (
                            <div>
                                <Row  key={`id-presuntive-${idExam + _a?.id}`}  >
                                <Col sm="2">
                                    <label className='txt-secondary' >{Tinción}</label>
                                    <Select
                                        isDisabled={!_validate ? false : true}
                                        className="basic-multi-select css-yk16xz-control-1 "
                                        classNamePrefix="select"
                                        name="id_mic_stain"
                                        options={dataStain}
                                        onChange={(e) => handleChangePresumptive(e, _a?.id, "id_mic_stain", idExam, _a.edicion)}
                                        value={_a?.id_mic_stain}
                                        styles={ _a?.idDelete ? deletePresuntive : !savePresuntive ?  newPresuntive  : saveIPresuntive}
                                        theme={
                                            (theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#898989'
                                                }
                                            })
                                        }
                                    />
                                </Col>
                                <Col sm="2" >
                                    <label className='txt-secondary'>{Result}</label>
                                    <Select
                                        isDisabled={!_validate ? false : true}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        name="id_mic_stain_result"
                                        options={dataPrecodText}
                                        value={_a?.id_mic_stain_result}
                                        onChange={(e) => handleChangePresumptive(e, _a?.id, "id_mic_stain_result", idExam, _a?.edicion)}
                                        styles={ _a?.idDelete ? deletePresuntive : !savePresuntive ?  newPresuntive  : saveIPresuntive}
                                        theme={
                                            (theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#898989'
                                                }
                                            })
                                        }
                                    />
                                </Col>
                                <Col sm="2" >
                                    <label className='txt-secondary'>{Microorganism}</label>
                                    <Select
                                        style={presumptive.edicion ? { background: "aliceblue" } : presumptive.remove ? { background: "#dc354545" } : {}}
                                        isDisabled={!_validate ? false : true}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        name="id_mic_bacterium"
                                        options={dataBacteriums}
                                        onChange={(e) => handleChangePresumptive(e, _a.id, "id_mic_bacterium", idExam, _a?.edicion)}
                                        styles={ _a?.idDelete ? deletePresuntive : !savePresuntive ?  newPresuntive  : saveIPresuntive}
                                        value={_a?.id_mic_bacterium}
                                        theme={
                                            (theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#898989'
                                                }
                                            })
                                        }
                                    />
                                </Col>
                                <Col sm="2" >
                                    <label className='txt-secondary'>{Observations}</label>
                                    <Input
                                        disabled={!_validate ? false : true}
                                        type="text"
                                        name="observations"
                                        className={  _a?.idDelete ? 'form-control form-control-sm input-air-danger border-danger input-mic'  : !savePresuntive ? 'form-control form-control-sm input-air-primary border-primary  input-mic' : 'form-control form-control-sm input-air-primary border-success input-mic'}
                                        value={_a?.observations}
                                        onChange={(e) => handleChangePresumptive(e, _a?.id, "observations", idExam, _a?.edicion)}
                                    />
                                </Col>
                                <Col sm="1"  >
                                    <label className='txt-secondary'>{Time}</label>
                                    <Input
                                        disabled={!_validate ? false : true}
                                        name='time'
                                        type="text"
                                        className={  _a?.idDelete ? 'form-control form-control-sm input-air-primary border-danger input-mic'  : !savePresuntive ? 'form-control form-control-sm input-air-primary border-primary  input-mic' : 'form-control form-control-sm input-air-primary border-primary input-mic'}
                                        value={_a?.time}
                                        onChange={(e) => handleChangePresumptive(e, _a?.id, "time", idExam, _a?.edicion)}
                                    />
                                </Col>
                                {
                                    //*VALIDATIONS BUTTONS  
                                }
                                <Col sm="3" className= "p-t-2 p-l-0  p-r-0 mt-2">
                                    <Col sm="12" className='p-l-0' >
                                        <Row>
                                            {
                                                //*ACTIONS
                                            }
                                            <Col sm="1"  className={ savePresuntive ? "d-none" :  _key === 0 ? "" :   "d-none"} > <br />
                                            <i id={`tooltip-addPresuntive-${_a?.id_presumptive_result}`} style={{ color: "#0079C7" }} onClick={() => addPresumptiveResult(idExam, "profile")} className={_key === 0 ? "fa fa-plus-circle f-28 pointer" : "d-none"}>
                                                <ToolTips
                                                    placement="top"
                                                    dataTarget={`tooltip-addPresuntive-${_a?.id_presumptive_result}`}
                                                    dataText={'Agregar nuevo presuntivo'}
                                                />
                                            </i>
                                            </Col>
                                            <Col  className={(_a.user_release === null && _a.user_validate === null )  ? "" : "d-none"}sm="1"> <br />
                                                <i onClick={() => (presumptive === 0 ? null : removePresumptiveResult(idExam,_a?.id_presumptive_result, _a?.id,"Section"))} className={presumptive === 0 ? "disabled fa fa-trash-o text-danger f-26 " : "fa fa-trash-o text-danger f-26 pointer"}></i>
                                            </Col>
                                            <Col sm="1" className={  ( _a?.id_presumptive_result !== null && _a?.user_release !== null ) ? 'pt-3' : "d-none" } >
                                            {
                                            ( _a?.id_presumptive_result !== null )  &&
                                            <>
                                            {
                                            _a?.print_result === false ? 
                                                <i id={`tooltip-print-${_a?.id_presumptive_result}`} className="icofont icofont-print f-28 pointer t" onClick={() => PrintingPresumptiveResult(idExam,_a?.id_presumptive_result,typeArray,examId,sectionProfileId)}>
                                                <ToolTips
                                                    placement="top"
                                                    dataTarget={`tooltip-print-${_a?.id_presumptive_result}`}
                                                    dataText={'Habilitar impresión'}
                                                />
                                            </i>
                                                :
                                                <i id={`tooltip-notPrint-${_a?.id_presumptive_result}`} className="icofont icofont-print text-success f-28 pointer" onClick={() => cancelPrintingPresumptive(idExam,_a?.id_presumptive_result, typeArray,examId,sectionProfileId)}></i>
                                            }
                                            </>
                                            }
                                            </Col>
                                            <Col sm="1" className={ ( _a?.id_presumptive_result !== null && _a?.user_validate !== null)  ?  "pt-3" : "d-none"} >
                                            {
                                            ( _a?.id_presumptive_result !== null && _a?.user_validate !== null) &&
                                            <>
                                                <img id={`tooltip-validate-user${_a?.id_presumptive_result}`} style={{ cursor: "pointer"  , width: "32px"}} className=" mr-1 rounded-circle" src={ UserValidate} alt="" />
                                                <ToolTips
                                                        placement="top"
                                                        dataTarget={`tooltip-validate-user${_a.id_presumptive_result}`}
                                                        dataText={"-"+_a?.validate_at+ "-" + "Capturado por:" + _a?.user_validate}
                                                />
                                            </>
                                            }
                                            </Col>
                                            {
                                                //*END ACTIONS
                                            }
                                            {
                                              //!VALIDATION CONFIGURATION
                                            }
                                            <Col  sm="6" className={savePresuntive ? "d-none" : 'pt-1 p-r-0' }  > <br />
                                            {
                                            ( _a.user_validate === null && _a.id_presumptive_result !== null) &&
                                             <Button tabIndex="-1" outline color='success' size='xs' onClick={() =>valdatePresumtiveResults(idExam,_a?.id_presumptive_result,typeArray,examId,sectionProfileId) } >Validar</Button>
                                            }
                                            {
                                            (_a.user_validate !== null && _a.id_presumptive_result !== null) && 
                                            <>
                                               {
                                                _a.user_release === null ? 
                                                <Button tabIndex="-1" size='xs' color='danger' outline onClick={() =>unvalidatePresumtiveResults(idExam,_a?.id_presumptive_result,typeArray,examId,sectionProfileId) }>Desvalidar</Button>
                                                :
                                                <Button tabIndex="-1" size='xs' color='danger' outline onClick={() =>unreleasePresumtiveResults(idExam,_a?.id_presumptive_result,typeArray,examId,sectionProfileId) }>Desvalidar</Button>
                                                }
                                                {
                                                _a.user_release === null ?
                                                <Button tabIndex="-1" size='xs' color='primary' outline onClick={() =>ReleasePresumtiveResults(idExam,_a?.id_presumptive_result,typeArray,examId,sectionProfileId) }>liberar</Button>
                                                :
                                                <label className='badge badge-light-primary f-12'>liberado</label>
                                               }
                                            </>
                                            }
                                            </Col>
                                            {
                                              //!END VALIDATION CONFIGURATION
                                            }
                                            {
                                                //*CANCELLATIONS
                                            }
                                            {/* <Col sm="1" className={savePresuntive ? "d-none" : 'p-l-0 p-r-0' }  ><br />
                                            <i className={_a?.idDelete ? "fa fa-ban f-26 pointer" : "d-none"}  onClick={() => cancelRemovePresumtiveResults(idExam,_a.id_presumptive_result, _a.id,"Section")}  id={`tooltip-cancelPresuntive-${_a.id_presumptive_result}`} style={{ color: "#ff0000" }} >
                                                <ToolTips
                                                    placement="top"
                                                    dataTarget={`tooltip-cancelPresuntive-${_a.id_presumptive_result}`}
                                                    dataText={'Cancelar'}
                                                   />
                                            </i>
                                            </Col> */}
                                            {
                                                //*END CANCELLATIONS
                                            }
                                        </Row>
                                    </Col>
                                </Col>
                                </Row> <br />
                                {//! DELETE PRESUMTIVE
                                    _a?.idDelete &&<AlertItem
                                    item={
                                        {
                                            alerttxt: <b className='txt-danger'>{MessageDelete}</b> ,
                                            alertcolor: 'danger inverse',
                                            btnclose: 'default',
                                            icon: <i className="icon-alert"></i>
                                        }
                                    }
                                />
                                }
                                
                            </div>
                            
                        )
                    })
                }
            </Col> 
        </>
    )
}
